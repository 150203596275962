import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

export const FullPageLoader: React.FC = () => (
  <Box
    position='fixed'
    top='0'
    left='0'
    width='100vw'
    height='100vh'
    display='flex'
    justifyContent='center'
    alignItems='center'
    backgroundColor='rgba(255, 255, 255, 0.8)'
    zIndex='1000'
  >
    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='gray.900' size='xl' />
  </Box>
);
