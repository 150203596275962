import axios from 'axios';
import { getInitData } from './auth';
import { StorageKey } from '../types';

const baseURL = process.env.REACT_APP_API_URL || 'https://diamore-propd.smart-ui.pro/';

export const loggedAxios = axios.create({
  baseURL,
});

loggedAxios.interceptors.request.use(
  config => {
    const isBackdoor = process.env.REACT_APP_IS_BACKDOOR === 'True';
    const backdoorID = localStorage.getItem(StorageKey.BackdoorID);
    const token = getInitData();

    if (isBackdoor && backdoorID) {
      config.headers.Authorization = `Backdoor ${backdoorID}`;
    } else if (token) {
      config.headers.Authorization = `Token ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

export const fetcher = (url: string) => {
  return loggedAxios.get(url).then(res => res.data);
};

export const API = {
  appVersion: '/v1/t-app',
  userInfo: '/user',
  userUpdate: '/user/update',
  syncClicks: '/taps/claim',
  questsList: '/quests',
  questInfo: (id: string) => `/quests/${id}`,
  questQuizOptions: (id: string) => `/quests/${id}/options`,
  questQuizAnswer: (id: string) => `/quests/${id}/answer`,
  finishQuest: 'quests/finish',
  leaderBoard: '/user/leaderboard',
  referralRecruits: '/referral/recruits/',
  claimReferral: '/referral/claim',
  claimReward: '/daily/claim',
  claimInvitation: '/referral/claim/invitation',
  assistantInvoice: '/assistant/invoice',
  lotteryTicketsInvoice: '/lottery-tickets/invoice',
  lotteryTicketsBuy: '/lottery-tickets/buy',
  lotteryCurrent: '/lottery/current',
  myWallet: 'user/my-wallet',
  updateWallet: 'user/add-or-update-wallet',
  startTrial: '/user/start-autoclicks/minute',
  startAuto24: '/user/start-autoclicks/day',
  sendPromocode: '/promo/apply-code',
  upgrades: '/upgrades',
  buyUpgrade: '/upgrades/buy',
  marketplace: '/marketplace/items',
  marketplaceItem: (id?: string) => `/marketplace/items/${id}`,
  marketplaceBuyItem: (id: string | number) => `/marketplace/buy/${id}`,
  marketplaceStaking: '/marketplace/items/my',
  filterOptions: '/marketplace/items/filter/options',
  marketplaceMy: '/marketplace/items/my',
  stakedItems: '/stake',
  stakeItem: (id: number) => `/stake/${id}`,
  stakeClaim: (id: string | number) => `/stake/${id}/claim`,
  stakeWithdraw: (id: string | number) => `/stake/${id}/widthdraw`,
  walletConnect: '/wallets/connect',
  checkWhitelist: '/wallets/check-whitelist',
  tapPadsEarning: '/tap-pads/earnings',
  tapPadsFeed: '/tap-pads/feed',
  tads: '/tads',
  tadsClaim: '/tads/claim',
  bingX: '/quest-tab/bing-x',
  myChipsAdUnit: '/my-chips/ad-unit',
  dailyRewards: '/daily/rewards',
  userVisit: '/user/visit',
  adsWatch: '/ads/watch',
  getAvailableAds: '/ads',
};

export * from './tganalytics';
