import * as ReactDOM from 'react-dom/client';
import { ColorModeScript, ChakraProvider } from '@chakra-ui/react';
//components
import { App } from './App';
// styles
import theme from './styles/theme';
import './assets/styles/style.css';
import './styles/scss/main.scss';
// other
import { ThemeProvider } from './context/ThemeContext';
import { LoadingProvider } from './context/LoadingContext';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <ChakraProvider theme={theme}>
    <ThemeProvider>
      <LoadingProvider>
        <ColorModeScript />
        <App />
      </LoadingProvider>
    </ThemeProvider>
  </ChakraProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
