import { useCallback, useEffect } from 'react';
import axios, { isAxiosError } from 'axios';
// other
import { useLocalStorage } from './useLocalStorage';
import { useInterval } from './useInterval';
import { StorageKey } from '../types';

export interface IGeolocation {
  cityName: string;
  continent: string;
  continentCode: string;
  countryCode: string;
  countryName: string;
  currency: { code: string; name: string };
  ipAddress: string;
  ipVersion: number;
  isProxy: boolean;
  language: string;
  latitude: number;
  longitude: number;
  regionName: string;
  timeZone: string;
  timeZones: Array<string>;
  tlds: Array<string>;
  zipCode: string;
  timeUpdate?: Date;
}

export interface IGeolocationConfig {
  mustGetNow?: boolean;
  updateInterval?: number;
  onUpdate?: (geolocation: IGeolocation) => void;
}

const ipApiUrl = 'https://freeipapi.com/api/json'; // free 60/minute = 86400/day ~ 2592000/month

export const useGeolocation = ({
  mustGetNow = true,
  updateInterval,
  onUpdate,
}: IGeolocationConfig = {}): IGeolocation => {
  const [geolocation, setGeolocation] = useLocalStorage<IGeolocation>(StorageKey.Geolocation);

  const getGeolocation = () =>
    axios<IGeolocation>(ipApiUrl)
      .then(response => response.data)
      .catch((error: unknown) => {
        if (isAxiosError(error)) {
          console.error('Fetch self info error:', error.message);
        }
      });

  const updateGeolocation = useCallback(async () => {
    const newGeolocation = await getGeolocation();

    if (newGeolocation) {
      onUpdate?.(newGeolocation);
      setGeolocation({ ...newGeolocation, timeUpdate: new Date() });
    }
  }, [onUpdate, setGeolocation]);

  useEffect(() => {
    !geolocation && mustGetNow && updateGeolocation();
  }, [geolocation, mustGetNow, updateGeolocation]);

  useInterval(updateGeolocation, { active: !!updateInterval, ms: updateInterval });

  return geolocation;
};
