import { isAxiosError } from 'axios';
import { API, loggedAxios } from '../api';
import { IGeolocation } from '../hooks';
import { IUser, IUserDto, StorageKey } from '../types';

type IUpdateUser = IUser & { geolocation?: IGeolocation };

const updateUserData = (userData: Partial<IUserDto>) => {
  loggedAxios.patch<any, any, Partial<IUserDto>>(API.userUpdate, userData).catch((error: unknown) => {
    if (isAxiosError(error)) {
      console.error('Update user error:', error.message);
    }
  });
};

const getUpdateUserConfig = (user: IUpdateUser) => {
  const { geolocation, ...userData } = user;

  const tg = window?.Telegram?.WebApp;
  const tgUser = tg.initDataUnsafe?.user;

  const updateConfig = {
    platform: tg.platform === 'unknown' ? undefined : tg.platform,
    country: geolocation?.countryName,
    username: tgUser?.username,
    first_name: tgUser?.first_name,
    last_name: tgUser?.last_name,
    is_premium: !!tgUser?.is_premium,
  };

  return Object.keys(updateConfig).reduce<Partial<typeof updateConfig>>((config, key) => {
    const userProp = userData[key as never];
    const configProp = updateConfig[key as never];
    if (configProp && userProp !== configProp) {
      config[key as keyof typeof updateConfig] = configProp;
    }
    return config;
  }, {});
};

export const checkAndUpdateUser = (user?: IUpdateUser) => {
  const userIdFromTelegram = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id.toString();
  const savedUserId = localStorage.getItem(StorageKey.TelegramUserId);
  const existingUserId = userIdFromTelegram || user?.id;

  if (savedUserId && savedUserId !== userIdFromTelegram) {
    localStorage.removeItem('ton-connect-storage_bridge-connection');
    localStorage.removeItem('ton-connect-ui_wallet-info');
    localStorage.removeItem('wallet');
  }

  // Adding user telegram context for development locally.
  if (!userIdFromTelegram && user) {
    window.Telegram.WebApp.initDataUnsafe.user = {
      id: Number(user?.id),
      first_name: user?.first_name,
      language_code: 'en',
    };
  }

  existingUserId && localStorage.setItem(StorageKey.TelegramUserId, existingUserId);

  if (user) {
    const config = getUpdateUserConfig(user);
    Object.keys(config).length && updateUserData(config);
  }
};
