/* eslint-disable no-console */
export const reassignLogging = () => {
  const originalLog = console.log;
  const originalInfo = console.info;
  const originalWarn = console.warn;

  const showLogging = process.env.NODE_ENV !== 'production';

  // Redefining logging
  console.log = function (...args) {
    showLogging && originalLog.apply(console, args);
  };

  console.warn = function (...args) {
    showLogging && originalWarn.apply(console, args);
  };

  console.info = function (...args) {
    showLogging && originalInfo.apply(console, args);
  };
};
