import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { isAndroid, isIOS } from 'react-device-detect';
import useSWR from 'swr';
// components
import { ToastContainer } from 'react-toastify';
import { FullPageLoader } from './components/FullPageLoader';
// styles
import 'react-toastify/dist/ReactToastify.css';
// other
import { ROUTES } from './constants';
import { API, fetcher } from './api';
import { useGeolocation, useInterval } from './hooks';
import { checkAndUpdateUser } from './utils/checkAndUpdateUser';
import { checkAppVersion } from './utils/checkAppVersion';
import { reassignLogging } from './utils/reassignLogging';
import { LoadingContext } from './context';
import { IUser, StorageKey } from './types';

// lazy import pages
const Welcome = lazy(() => import('./pages/Welcome'));
const OnboardingFirstScreen = lazy(() => import('./pages/Onboarding/Screen1'));
const OnboardingSecondScreen = lazy(() => import('./pages/Onboarding/Screen2'));
const OnboardingThirdScreen = lazy(() => import('./pages/Onboarding/Screen3'));
const Assistant = lazy(() => import('./pages/Assistant'));
const Home = lazy(() => import('./pages/Home'));
const Lottery = lazy(() => import('./pages/Lottery'));
const Diamonds = lazy(() => import('./pages/Diamonds'));
const DiamondsDetails = lazy(() => import('./pages/Diamonds/Details'));
const DiamondsPurchase = lazy(() => import('./pages/Diamonds/Purchase'));
const DailyReward = lazy(() => import('./pages/DailyReward'));
const Quests = lazy(() => import('./pages/Quests'));
const Instruction = lazy(() => import('./pages/Quests/containers/Instruction'));
const BingX = lazy(() => import('./pages/Quests/containers/BingX'));
const MyChips = lazy(() => import('./pages/Quests/containers/MyChips'));
const MyLead = lazy(() => import('./pages/Quests/containers/MyLead'));
const Quiz = lazy(() => import('./pages/Quests/containers/Quiz'));
const Tads = lazy(() => import('./pages/Quests/containers/Tads'));
const TapPads = lazy(() => import('./pages/Quests/containers/TapPads'));
const Community = lazy(() => import('./pages/Community'));
const Nft = lazy(() => import('./pages/Nft'));
const Bkd = lazy(() => import('./pages/Bkd'));
const DesktopPage = lazy(() => import('./pages/DesktopPage'));
const ErrorScreen = lazy(() => import('./components/ErrorScreen'));

interface IRoutingProps {
  balance: string;
  isInvitationReward: boolean;
}

const Routing: React.FC<IRoutingProps> = ({ balance, isInvitationReward }) => (
  <Router>
    <Suspense fallback={<FullPageLoader />}>
      <Routes>
        <Route
          path={ROUTES.root}
          element={
            <Navigate
              to={
                isInvitationReward
                  ? ROUTES.welcome
                  : Number(balance) > 0
                    ? ROUTES.home
                    : ROUTES.onboardingStep1
              }
            />
          }
        />
        <Route path={ROUTES.welcome} element={<Welcome />} />
        <Route path={ROUTES.onboardingStep1} element={<OnboardingFirstScreen />} />
        <Route path={ROUTES.onboardingStep2} element={<OnboardingSecondScreen />} />
        <Route path={ROUTES.onboardingStep3} element={<OnboardingThirdScreen />} />
        <Route path={ROUTES.assistant} element={<Assistant />} />
        <Route path={ROUTES.home} element={<Home />} />
        <Route path={ROUTES.lottery} element={<Lottery />} />
        <Route path={ROUTES.diamonds} element={<Diamonds />} />
        <Route path={ROUTES.diamondDetail} element={<DiamondsDetails />} />
        <Route path={ROUTES.diamondPurchase} element={<DiamondsPurchase />} />
        <Route path={ROUTES.quests} element={<Quests />} />
        <Route path={ROUTES.questsInstruction} element={<Instruction />} />
        <Route path={ROUTES.quiz} element={<Quiz />} />
        <Route path={ROUTES.bingX} element={<BingX />} />
        <Route path={ROUTES.tapPads} element={<TapPads />} />
        <Route path={ROUTES.tads} element={<Tads />} />
        <Route path={ROUTES.myChips} element={<MyChips />} />
        <Route path={ROUTES.myLead} element={<MyLead />} />
        <Route path={ROUTES.dailyReward} element={<DailyReward />} />
        <Route path={ROUTES.community} element={<Community />} />
        <Route path={ROUTES.nft} element={<Nft />} />
        <Route path={ROUTES.bkd} element={<Bkd />} />
      </Routes>
    </Suspense>
  </Router>
);

const MobileOnly: React.FC = () => {
  const { loading } = useContext(LoadingContext);
  const { data: user, isLoading: isUserLoading, error } = useSWR<IUser>(API.userInfo, fetcher);
  const { isLoading: isQuestsLoading } = useSWR(API.questsList, fetcher);

  const isLoading = isUserLoading || isQuestsLoading || loading;
  const geolocation = useGeolocation();

  reassignLogging();
  useInterval(checkAppVersion, { ms: 60000 });

  useEffect(() => {
    checkAppVersion();
  }, []);

  useEffect(() => {
    user && checkAndUpdateUser({ ...user, geolocation });
  }, [user, geolocation]);

  if (error) {
    return (
      <Suspense fallback={<FullPageLoader />}>
        <ErrorScreen />
      </Suspense>
    );
  }

  return (
    <>
      {isLoading && <FullPageLoader />}
      <div>
        <ToastContainer
          className='custom-toast'
          autoClose={3000}
          closeButton={false}
          closeOnClick
          hideProgressBar
        />
        {user && <Routing balance={user?.balance} isInvitationReward={user?.invitationReward} />}
      </div>
    </>
  );
};

export const App: React.FC = () => {
  const isMobile = isAndroid || isIOS;
  const platform = window?.Telegram?.WebApp.platform;
  const isMobileApp = platform === 'android' || platform === 'ios';
  const isTest = process.env.REACT_APP_TG_TOKEN;
  const manifestUrl = `${window.location.origin}/tonconnect-manifest.json`;
  const sessionId = crypto.randomUUID();
  const appName = String(process.env.REACT_APP_NAME);

  useEffect(() => {
    if (sessionId) {
      window?.sessionStorage.setItem(StorageKey.SessionId, sessionId);
    }

    return () => {
      window?.sessionStorage.removeItem(StorageKey.SessionId);
    };
  }, [sessionId]);

  useEffect(() => {
    try {
      window.telegramAnalytics.init({
        token: String(process.env.REACT_APP_TELEGRAM_ANALYTICS_TOKEN),
        appName,
      });
      console.log('Telegram Analytics SDK initialized successfully'); // eslint-disable-line no-console
    } catch (error) {
      console.error('Error initializing Telegram Analytics SDK:', error);
    }
  }, [appName]);

  if (isTest || (isMobile && isMobileApp)) {
    return (
      <TonConnectUIProvider manifestUrl={manifestUrl}>
        <MobileOnly />
      </TonConnectUIProvider>
    );
  }
  return (
    <Suspense fallback={<FullPageLoader />}>
      <DesktopPage />
    </Suspense>
  );
};
